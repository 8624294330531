import React, {useEffect, useMemo} from 'react';
import { useProductViewState } from '../../../state';
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export const Description = () => {
    const { description, loading, productAttributes } = useProductViewState() || {};

    const decodedDescription = useMemo(() => {
        return description ? decodeHtml(description) : null;
    }, [description]);

    if (loading) {
        return <LoadingIcon/>;
    }

    if (!decodedDescription) {
        return null;
    }

    return (
        <div className={'react-css bg-white xl:mb-5 mb-2 mt-5'}
             id={'productDescription'}>
            <div className={'bg-cards-bg font-text-color'}>
                <h2
                    className={'react-css uppercase font-lato tracking-wider font-semibold leading-[50px] font-text-color text-2xl pl-10 pr-10'}>
                    {'Detalii'}
                </h2>
            </div>
            <div
                className={'react-css p-3 border-2 border-solid border-cards-bg flex flex-col items-start'}>
                <div className={'react-css flex justify-center items-center'}>
                    <h2 className={'react-css text-2xl font-lato font-text-color tracking-wider text-left mb-8 flex flex-row justify-start gap-5 w-full'}>
                        <span className={'react-css font-bold text-left text-2xl'}>{productAttributes?.name}</span>
                    </h2>
                </div>
                <div
                    className={'font-lato font-text-color text-xl xl:text-2xl w-full'}
                    dangerouslySetInnerHTML={{__html: decodedDescription}}
                />
            </div>
        </div>
    );
}
