import React, {useState, useEffect} from 'react';
import {useCategoriesAndProductsContextProvider} from '../../state';

declare global {
    interface Window {
        BASE_URL: string;
    }
}

export const CategoryButton: React.FC = () => {
    const {firstLevelCategories, categoriesData, loading} = useCategoriesAndProductsContextProvider();
    const [selectedCategory, setSelectedCategory] = useState<any | null>(null);

    useEffect(() => {
        if (categoriesData.length > 0) {
            const element = document.querySelector('.page-header');
            if (element) {
                const topPos = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: topPos, behavior: 'smooth' });
            }
        }
    }, [categoriesData]);

    const handleCategoryClick = (categoryName: string) => {
        const selectedCat = categoriesData?.find(cat => cat.name === categoryName);
        setSelectedCategory(selectedCat || null);
    };

    useEffect(() => {
        // Automatically set the first available category as the selected category
        if (firstLevelCategories.length > 0 && categoriesData) {
            const initialCategory = categoriesData.find(cat => cat.name === firstLevelCategories[0]);
            setSelectedCategory(initialCategory);
        }
    }, [firstLevelCategories, categoriesData]);

    if (loading)
        return (
            <div
                className={'react-css fixed top-0 left-0 w-full h-full flex flex-col text-white items-center justify-center bg-black bg-opacity-50 z-50'}>
                <span className={'react-css text-white text-2xl md:text-3xl font-bold'}>Se incarca promotiile ...</span>
            </div>
        );

    return (
        <div>
            <div className={'grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-5 justify-items-center'}>
                {firstLevelCategories.map((categoryName) => (
                    <button
                        key={categoryName}
                        onClick={() => handleCategoryClick(categoryName)}
                        className={'bg-main-color focus:outline-none focus:bg-main-color active:bg-main-color ' +
                            'text-white text-4xl font-lato font-bold p-5 rounded w-11/12 ' +
                            'hover:bg-main-color hover:text-white hover:shadow-first-btn-shadow hover:scale-110 ' +
                            'transition-all duration-300 ease-in-out'}
                    >
                        {categoryName}
                    </button>
                ))}
            </div>
            <div className={'mt-5'}>
                {selectedCategory?.children?.map((subCategory) => (
                    <div key={subCategory.id}>
                        {subCategory?.products?.items?.length > 0 ? (
                            <h2
                                className={'react-css text-center text-black-friday font-prata text-4xl ' +
                                    'font-text-color tracking-wide p-5 mt-7 mb-5'}
                            >
                                {subCategory?.name}
                            </h2>
                        ) : null}
                        {/* Show products of each subcategory */}
                        <div className={'grid grid-cols-1 md:grid-cols-3 gap-6'}>
                            {subCategory?.products?.items?.map((product) => (
                                <div key={product.id}
                                     className={'relative bg-black-friday rounded p-4 shadow flex items-center flex-col min-h-[480px] ' +
                                         'hover:shadow-first-btn-shadow transition-all hover:border border-slate-400 duration-300 ease-in-out ' +
                                         'grid grid-row-10 p-5 m-3 md:m-0 md:mb-5'}>
                                    {/*<p className={'absolute left-0 top-0 text-slate-100 m-5 p-3 text-2xl font-bold text-center bg-black-friday'}>*/}
                                    {/*    {'Black Friday'}*/}
                                    {/*</p>*/}
                                    {product?.stock_status === "OUT_OF_STOCK" && (
                                        <div
                                            className={'absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-10'}>
                                            <span
                                                className={'react-css text-rose-700 text-2xl font-text-color mr-48 md:mb-48 md:mr-0'}>OUT OF STOCK</span>
                                        </div>
                                    )}
                                    <a className={'row-span-4 min-h-[250px] flex justify-center items-center'}
                                       href={`${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}>
                                        <img src={product?.customProductImageUrl?.url} alt={product?.small_image?.label}
                                             className="w-full object-cover object-center" loading="lazy"/>
                                    </a>
                                    <div className={'row-span-6 flex flex-col gap-4 min-h-[230px]'}>
                                        <a className={'min-h-[90px] text-black hover:underline active:text-black'}
                                           href={`${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}>
                                            <h2 className={'react-css text-3xl font-bold font-lato leading-9 mt-4 text-white'}>
                                                {product?.name?.length > 75 ? `${product?.name.substring(0, 75)}...` : product?.name}
                                            </h2>
                                        </a>
                                        <div className={'mt-2 min-h-[60px]'}>
                                            {product?.price_range?.minimum_price?.regular_price?.value === product?.price_range?.minimum_price?.final_price?.value ? (
                                                <p
                                                    className={'react-css text-3xl font-bold text-white'}>
                                                    {parseFloat(String(product?.price_range?.minimum_price?.regular_price?.value)).toFixed(2)} Lei
                                                </p>
                                            ) : (
                                                <div>
                                                    <p
                                                        className={'react-css text-xl font-semibold line-through text-white'}>
                                                        {parseFloat(String(product?.price_range?.minimum_price?.regular_price?.value)).toFixed(2)} Lei
                                                    </p>
                                                    <p
                                                        className={'react-css text-3xl font-bold text-red-400'}>
                                                        {parseFloat(String(product?.price_range?.minimum_price?.final_price?.value)).toFixed(2)} Lei
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <button
                                            className={'uppercase tracking-wider min-h-[50px] bg-main-color focus:outline-none focus:bg-main-color active:bg-main-color ' +
                                                'text-white text-2xl font-lato font-bold p-5 rounded w-4/5 ' +
                                                'hover:bg-main-color hover:text-white transition-all duration-300 ease-in-out'}
                                            onClick={() => window.location.href = `${window.BASE_URL}${product?.url_rewrites[0]?.url || ''}`}
                                        >
                                            Vezi detalii
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {subCategory?.products?.items?.length > 0 ? (
                            <button
                                className={
                                    "rounded bg-first-btn-bg hover:bg-first-btn-bg focus:bg-first-btn-bg " +
                                    "font-lato font-bold text-2xl text-center text-white focus:text-white hover:text-white " +
                                    "shadow-see-more-shadow transform hover:translate-y-1 mt-2 py-3 px-4 " +
                                    "flex items-center justify-center space-x-2 border-orange-700"
                                }
                                type="button"
                                onClick={() => window.location.href = `${window.BASE_URL}lichidari-de-stoc.html`}>
                                Vezi mai multe produse din oferta!
                            </button>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
};
