import React, {useState} from 'react';
import {useCheckoutState} from "../modules/Checkout/state/context/CheckoutProvider";
import {useWindowSize} from './useWindowSize';
import {LoadingIcon} from './assets/LoadingIcon';

export const CardItem = ({product}) => {
    const {isMobile, isTablet} = useWindowSize();
    const {handleAddToCart, addingToCart} = useCheckoutState();
    const [qty, setQty] = useState(1);

    if (!product) return null;

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'Lei' }).format(price);
    };

    const regularPriceValue = product?.price_range?.minimum_price?.regular_price?.value;
    const finalPriceValue = product?.price_range?.minimum_price?.final_price?.value;

    const regularPriceFormatted = formatPrice(regularPriceValue);
    const finalPriceFormatted = formatPrice(finalPriceValue);

    const discountValue = regularPriceValue - finalPriceValue;
    const discountValueFormatted = formatPrice(discountValue);

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        if (!customAttributes) return null;
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const msrpValue = parseFloat(findCustomAttributeValue(product?.custom_attributes, 'msrp') || 0);
    const msrpFormatted = msrpValue > 0 ? formatPrice(msrpValue) : null;

    const productName = product?.name || '';
    const cutProductName = productName.length > 70 ? productName.substring(0, 67) + '...' : productName;

    const productUrl = `${window.BASE_URL}${product?.url_rewrites?.[0]?.url || ''}`;
    const isInStock = findCustomAttributeValue(product?.custom_attributes, 'is_in_stock');

    return (
        <div
            className="relative bg-white px-2 py-2.5 justify-between items-center gap-1 hover:shadow-box-shadow
                p-2.5 px-5 border border-border-color rounded-xl flex flex-col items-center jistify-center w-auto h-auto xl:h-[400px]">
            {discountValue != 0 && (
                <p className={'absolute top-[8px] left-[8px] h-[20px] pl-2 pr-2 text-white text-lg xl:text-xl font-medium text-center bg-custom-red ' +
                    'flex items-center justify-center font-lato'}>
                    {`- ${discountValueFormatted}`}
                </p>
            )}

            <a className={'h-[150px] xl:h-[200px] flex justify-center items-center '}
               href={productUrl}
               title={product?.name}
            >
                <img src={product?.customProductImageUrl?.url} alt={product?.small_image?.label}
                     className={'w-11/12 object-contain object-center max-h-[150px] xl:max-h-[200px] text-sm'}
                     loading="lazy"/>
            </a>

            <div className={'grid grid-row-3 gap-2 h-[135px] xl:h-[185px] w-full'}>
                <a
                    className={'h-[40px] text-text-color hover:no-underline hover:text-text-color active:text-text-color overflow-hidden '}
                    href={productUrl}
                    title={product?.name}
                >
                    <p className={'react-css text-left xl:text-xl font-medium font-lato text-text-color ' +
                        'h-[40px] overflow-hidden'}>
                        {cutProductName}
                    </p>

                </a>
                <div className={'h-[40px] xl:h-[50px] flex flex-col justify-end items-start'}>
                    {regularPriceValue === 0 ? (
                        <div></div>
                    ) : (
                        <>
                            {regularPriceValue === finalPriceValue ? (
                                <div className={'react-css flex flex-col items-left gap-0 xl:gap-1'}>
                                    {msrpFormatted && (
                                        <div className={'react-css flex flex-row items-start'}>
                                        <span
                                            className={'react-css text-lg xl:text-xl font-medium font-lato ml-2'}>{'PRP:'}</span>
                                            <span
                                                className={'react-css text-lg xl:text-xl font-medium font-lato ml-2'}>
                                            {' '}{msrpFormatted}
                                        </span>
                                        </div>
                                    )}
                                    <p className={'react-css text-2xl text-left font-bold text-custom-red font-lato ml-2'}>
                                    <span
                                        className={'react-css'}>
                                        {finalPriceFormatted}{' cu TVA'}
                                    </span>
                                    </p>
                                </div>
                            ) : (
                                <div className={'flex flex-col items-start'}>
                                    <div className={'flex flex-row gap-2 items-start'}>
                                        <p className={'react-css text-lg xl:text-xl font-medium line-through font-lato ml-2'}>
                                            {regularPriceFormatted}
                                        </p>
                                    </div>

                                    <p className={'react-css text-2xl font-bold text-custom-red font-lato'}>
                                        <span
                                            className={'react-css ml-2'}>
                                            {finalPriceFormatted}{' cu TVA'}
                                        </span>
                                    </p>
                                </div>
                            )}
                        </>

                    )}
                </div>

                <div className={'flex justify-center'}>
                    {product?.type_id != 'simple' ? (
                        <button
                            className="uppercase tracking-wider h-[45px] xl:h-[50px] bg-main-color focus:outline-none focus:bg-main-color active:bg-main-color
                                       text-white text-xl xl:text-2xl font-lato font-bold p-5 rounded w-full flex items-center justify-center
                                       hover:bg-main-color hover:text-white transition-all duration-300 ease-in-out"
                            onClick={() => window.location.href = productUrl}
                        >
                            <span className={'uppercase text-lg font-text-color'}>{'Vezi detalii'}</span>
                        </button>
                    ) : (
                        <button
                            className="uppercase tracking-wider h-[45px] xl:h-[50px] bg-main-color focus:outline-none focus:bg-main-color active:bg-main-color
                                       text-white text-2xl font-lato font-bold p-5 rounded w-full flex items-center justify-center
                                       hover:bg-main-color hover:text-white transition-all duration-300 ease-in-out"
                            onClick={() => handleAddToCart({sku: product?.sku, quantity: qty ? qty : 1})}
                        >
                            {isMobile || isTablet ? (
                                <>
                                    {product?.sku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <svg className={''}
                                             xmlns="http://www.w3.org/2000/svg" height="24" width="24"
                                             viewBox="0 0 576 512">
                                            <path fill="#F3F4F1"
                                                  d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                          45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                          28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                          24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                          48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                        </svg>
                                    )}
                                </>
                            ) : (
                                <>
                                    {product?.sku === addingToCart ? (
                                        <LoadingIcon/>
                                    ) : (
                                        <>
                                                <span
                                                    className={'font-lato text-lg text-white'}>{'Adauga in cos'}</span>
                                            <svg className={'ml-5'}
                                                 xmlns="http://www.w3.org/2000/svg" height="24" width="26"
                                                 viewBox="0 0 576 512">
                                                <path fill="#F3F4F1"
                                                      d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0
                                                              45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4
                                                              28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24
                                                              24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7
                                                              48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                                            </svg>
                                        </>
                                    )}
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
