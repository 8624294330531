import React, {createContext, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {request} from "../../../Infrastructure";
import {getGuestCart, getCustomerCart, getAddToCart, getCreateGuestCart} from "../../queries";

type CartItemInputType = {
    sku: string
    quantity: number
}

type CheckoutStateType = {
    handleAddToCart: (data) => void;
    addingToCart: string;
    errorMessage: string | null;
};

const CheckoutStateContext = createContext<CheckoutStateType | undefined>(undefined);

export const CheckoutProvider = ({children}) => {
    const [cartId, setCartId] = useState(null);
    const [addingToCart, setAddingToCart] = useState<string>('null');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const getGuestCartIdMutation = useMutation({
        mutationKey: ['createGuestCart'],
        mutationFn: () => request(getCreateGuestCart),
        onSuccess: (response) => {
            const cartId = response.data.createEmptyCart;
            // console.log('Guest Cart ID:', cartId);
            //@ts-ignore
            setCartId(cartId);
        }
    });

    const getGuestAddToCartMutation = useMutation({
        mutationKey: ['addToGuestCart'],
        mutationFn: (data: CartItemInputType) => request(getAddToCart, {cartId, cartItems: [data]}),
        onSuccess: (response) => {
            setAddingToCart('null');
            // console.log('Add to Cart:', response);
            const setErrorMessageFromResponse = (response) => {
                if (response.data?.addProductsToCart?.user_errors?.length > 0) {
                    return response.data.addProductsToCart.user_errors.map(error => error.message).join(', ');
                }

                if (response.errors?.length > 0) {
                    return response.errors[0].debugMessage || 'An error occurred';
                }

                return null;
            };

            setErrorMessage(setErrorMessageFromResponse(response));

            document.dispatchEvent(new CustomEvent('customEventReload', { detail: ['cart'] }));
            document.dispatchEvent(new CustomEvent('specialCartEventPopup', {}));
        }
    });

    const handleAddToCart = (data: CartItemInputType) => {
        setAddingToCart(data.sku);
        if (!cartId) {
            getGuestCartIdMutation.mutate(undefined, {
                onSuccess: (response) => {
                    setAddingToCart(null);
                    setCartId(response.data.createEmptyCart);
                    getGuestAddToCartMutation.mutate(data)
                }
            });
        } else {
            getGuestAddToCartMutation.mutate(data);
        }
    };

    return (
        <CheckoutStateContext.Provider value={
            {
                handleAddToCart,
                addingToCart,
                errorMessage,
            }
        }>
            {children}
        </CheckoutStateContext.Provider>
    );
};

export const useCheckoutState = () => {
    const context = useContext(CheckoutStateContext);
    if (context === undefined) {
        throw new Error("useCheckoutState must be used within a CheckoutProvider");
    }
    return context;
};
