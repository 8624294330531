import React from 'react';
import {ReviewFormInner} from "./ReviewFormInner";
import {FormProvider, useForm} from 'react-hook-form';

export const ReviewForm = ({productSku}) => {
    const form = useForm();
    return (
        <FormProvider {...form}>
            <ReviewFormInner/>
        </FormProvider>

    );
}
