import React, {useState} from 'react';
import {ExpandButton, Stars} from "../../../../../general";
import {useProductViewState} from '../../../state';

const getReviewsByRating = (reviews, rating: number) => {
    let totalReviews = reviews.length;
    let voteCount = 0;

    let filteredReviews = reviews.filter(review => {
        let ratingVotes = review.ratings_breakdown.filter(vote => Number(vote.value) === rating);
        voteCount += ratingVotes.length;
        return ratingVotes.length > 0;
    });

    let percentage = Math.round((filteredReviews.length / totalReviews) * 100);

    return {
        reviews: filteredReviews,
        percentage,
        voteCount
    };
};

export const Review = () => {
    const {productReviews, error} = useProductViewState() || {};
    const [isExpanded, setIsExpanded] = useState(false);

    if (error || !productReviews || productReviews.length === 0) {
        return null;
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleButtonClick = () => {
        toggleExpand();
        const element = document.getElementById('react-reviews');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const numberOfReviews = productReviews.length;
    const totalStarsValue = productReviews.reduce((sum, review) => {
        const reviewStars = review.ratings_breakdown.reduce((rSum, vote) => rSum + Number(vote.value), 0) / review.ratings_breakdown.length;
        return sum + reviewStars;
    }, 0);

    const averageStars = numberOfReviews > 0 ? (totalStarsValue / numberOfReviews).toFixed(1) : "0.0";
    const averageStarsNumber = Number(averageStars);

    let fiveStarReviews = getReviewsByRating(productReviews, 5);
    let fourStarReviews = getReviewsByRating(productReviews, 4);
    let threeStarReviews = getReviewsByRating(productReviews, 3);
    let twoStarReviews = getReviewsByRating(productReviews, 2);
    let oneStarReviews = getReviewsByRating(productReviews, 1);

    return (
        <div className={'react-css relative rounded-xl bg-white md:mb-10 mb-5'} id={'react-reviews'}>
            <div className={'bg-cards-bg font-text-color'}>
                <h2
                    className={'react-css uppercase font-lato tracking-wider font-semibold leading-[50px] font-text-color text-2xl pl-10 pr-10'}>
                    {'Evaluari'}
                </h2>
            </div>
            <div
                className={`react-css overflow-hidden transition-all duration-300 ${isExpanded ? 'max-h-full' : 'max-h-[600px]'} p-6`}>
                <div
                    className={'react-css border-b-2 mb-8 lex-row flex md:flex-row flex-col items-start justify-start md:gap-8 gap-5 pb-5'}>
                    <div className={'react-css flex flex-row items-center justify-start gap-5 '}>
                        <p className={'react-css text-custom-gray font-prata text-8xl italic font-black leading-none tracking-normal'}>
                            {averageStarsNumber}
                        </p>
                        <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                        <span className={'react-css flex flex-row items-center justify-start gap-1 text-custom-gray'}>
                            <Stars totalStars={averageStarsNumber} starSize={'h-12 w-12'}/>
                        </span>
                            <p className={'react-css flex flex-row gap-2 items-center text-custom-gray font-lato text-lg font-bold leading-tight tracking-normal'}>
                                <span className={'react-css font-lato text-lg font-bold'}>{numberOfReviews}</span>
                                Evaluari
                            </p>
                        </div>
                    </div>
                    <div className={'flex flex-row gap-10 xl:gap-5'}>
                        <div className={'react-css flex flex-col items-start justify-start gap-2'}>
                            <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                                <span className={'react-css font-lato text-lg font-bold'}>5 </span>
                                <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={5} starSize={'h-4 w-4'}/>
                            </span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>{fiveStarReviews.percentage}%</span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>({fiveStarReviews.voteCount})</span>
                            </p>
                            <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                                <span className={'react-css font-lato text-lg font-bold'}>4 </span>
                                <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={4} starSize={'h-4 w-4'}/>
                            </span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>{fourStarReviews.percentage}%</span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>({fourStarReviews.voteCount})</span>
                            </p>
                            <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                                <span className={'react-css font-lato text-lg font-bold'}>3 </span>
                                <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={3} starSize={'h-4 w-4'}/>
                            </span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>{threeStarReviews.percentage}%</span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>({threeStarReviews.voteCount})</span>
                            </p>
                            <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                                <span className={'react-css font-lato text-lg font-bold'}>2 </span>
                                <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={2} starSize={'h-4 w-4'}/>
                            </span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>{twoStarReviews.percentage}%</span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>({twoStarReviews.voteCount})</span>
                            </p>
                            <p className={'react-css flex flex-row items-center justify-start gap-2'}>
                                <span className={'react-css font-lato text-lg font-bold'}>1 </span>
                                <span className={'react-css flex flex-row items-center justify-start gap-1'}>
                            <Stars totalStars={1} starSize={'h-4 w-4'}/>
                            </span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>{oneStarReviews.percentage}%</span>
                                <span
                                    className={'react-css font-lato text-lg font-bold'}>({oneStarReviews.voteCount})</span>
                            </p>
                        </div>
                        <div className={'react-css flex flex-col items-start justify-start gap-3'}>
                            <button onClick={() => window.location.href = '#review-form'}
                                    className={
                                        "rounded bg-first-btn-bg hover:bg-first-btn-bg focus:bg-first-btn-bg " +
                                        "font-prata font-bold text-lg text-center text-white focus:text-white hover:text-white " +
                                        "hover:shadow-second-btn-shadow mt-2 w-full py-3 px-4 md:mb-0 mb-5 " +
                                        "flex items-center justify-center z-10"
                                    }
                                    type="button"
                            >
                                <p className="react-css">
                                    Scrie o evaluare
                                </p>
                            </button>
                        </div>
                    </div>
                </div>

                {productReviews.map((review, index) => (
                    <div className={'mb-8 border-b-2 flex md:flex-row flex-col'} key={index}>
                        <div
                            className={'flex md:flex-col flex-row gap-3 items-start justify-start md:w-3/12 mr-3 md:mb-0 mb-3'}>
                            <span
                                className={'react-css font-lato font-bold text-text-color text-xl'}>{review.nickname}</span>
                            <span className={'react-css text-xl font-lato font-normal'}>{review.created_at}</span>
                        </div>
                        <div className={'flex flex-col gap-3 md:w-9/12'}>
                            <h2 className={'react-css font-lato font-bold text-text-color text-xl'}>{review.summary}</h2>
                            <div className={'mb-5'}>
                                {review.ratings_breakdown.map((rating, ratingIndex) => (
                                    <div className={'flex flex-row items-center justify-start space-x-8'}
                                         key={ratingIndex}>
                                        <div className={'flex flex-row items-center justify-start space-x-1'}>
                                            <Stars totalStars={Number(rating.value)} starSize={'h-8 w-8'}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <p className={'react-css font-lato text-xl font-normal text-text-color mb-5'}>{review.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* opacity efect over the closed div */}
            <div
                className={`react-css absolute top-0 left-0 w-full h-full transition-all duration-300 ${isExpanded ? 'hidden' : 'bg-custom-gradient'} rounded-2xl`}></div>
            {/* The "See More/Less" button */}
            <div className={'react-css flex items-center justify-center md:mb-0 mb-5'}>
                <ExpandButton
                    isExpanded={isExpanded}
                    onClick={handleButtonClick}
                    expandedText="Ascunde recenziile"
                    collapsedText="Vezi toate recenziile"
                />
            </div>
        </div>
    );
}
