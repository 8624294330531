import React from 'react';
import {useProductViewState} from '../../../state';
import {useWindowSize, CardItem} from "../../../../../general";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {Navigation} from 'swiper/modules';
import {LoadingIcon} from "../../../../../general/assets/LoadingIcon";

export const AutoRelated = () => {
    const {autoRelatedProducts, loading} = useProductViewState() || {};
    const {isMobile, isTablet} = useWindowSize();
    const itemsToShow = isMobile ? 2 : isTablet ? 4 : 5;
    const gapBetween = isMobile ? 0 : 0;

    const findCustomAttributeValue = (customAttributes, attributeCode) => {
        if (!customAttributes) return null;
        const attribute = customAttributes.find(attr => attr.attribute_code === attributeCode);
        return attribute ? attribute.value : null;
    };

    const availableProducts = autoRelatedProducts?.filter(product => {
        const isInStock = findCustomAttributeValue(product?.custom_attributes, 'is_in_stock');
        return isInStock !== "Out of Stock";
    }) || [];

    if (loading) {
        return <LoadingIcon/>;
    }

    if (!availableProducts.length) return null;

    let marginLeft;
    if (isMobile) {
        marginLeft = availableProducts.length > 2 ? 'ml-[18%]' : 'ml-[0]';
    } else if (isTablet) {
        marginLeft = availableProducts.length >= 5 ? 'ml-[10%]' : 'ml-[0]';
    } else {
        marginLeft = availableProducts.length > 5 ? 'ml-[5%]' : 'ml-[0]';
    }

    return (
        <div className={'relative react-css mt-5'}>
            <h2 className={'absolute react-css uppercase font-lato tracking-wider font-semibold leading-[40px] xl:leading-[50px] ' +
                'font-text-color text-2xl xl:pl-10 xl:pr-10 xl:w-full ' +
                ' z-[2] pl-5 ' + marginLeft}>
                {'Produse similare'}
            </h2>
            <Swiper navigation={true}
                    modules={[Navigation]}
                    slidesPerView={itemsToShow}
                    spaceBetween={gapBetween}
                    freeMode={true}
                    className={'react-slider'}
            >
                {availableProducts.map((product) => (
                    <SwiperSlide key={product.id}
                                 className={'px-1 py-2 !w-[49.4%] md:!w-[24.8%] xl:!w-[19.9%]'}
                    >
                        <CardItem product={product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
