import React, { useState, useEffect } from 'react';

export const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        isVisible && (
            <div
                id="scrollToTopButton"
                onClick={scrollToTop}
                style={{ position: 'fixed', bottom: '60px', right: '14px', cursor: 'pointer' }}
                className="bg-primary p-2 rounded-full shadow-md text-white z-50"
                aria-label="Scroll to top"
            >
                <svg height="50px" width="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     viewBox="0 0 473.654 473.654" xmlSpace="preserve">
                    <circle style={{ fill: '#6EC2CB' }} cx="236.827" cy="236.827" r="236.827"/>
                    <path style={{ fill: '#FFFFFF' }} d="M366.553,238.843c-35.885-35.889-71.766-71.77-107.651-107.655
              c-10.837-10.845-28.907-10.549-39.666,0.213c-35.885,35.881-71.77,71.77-107.651,107.655c-25.564,25.56,14.259,65.08,39.879,39.456
              c29.236-29.236,58.476-58.476,87.712-87.712c29.311,29.307,58.614,58.618,87.925,87.929
              C352.664,304.289,392.184,264.47,366.553,238.843z"/>
                </svg>
            </div>
        )
    );
};
