import React, {createContext, useState, useEffect, useContext, ReactNode, useRef, useCallback} from 'react';
import {request} from '../../../Infrastructure';
import {getCmsBlock, getCategoryAndProducts} from '../../queries';
import {useQuery} from "@tanstack/react-query";
import useLocalStorageState from 'use-local-storage-state'

interface Product {
    id: string;
    sku: string;
    name: string;
    stock_status: string;
    custom_attributes?: {
        attribute_code: string;
        value: string;
        value_id?: string;
    }[];
    url_key: string;
    url_rewrites: { url: string; }[];
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    media_gallery: { url: string; label: string; }[];
    short_description: { html: string };
    description: { html: string };
    price_range: {
        minimum_price: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    new_from_date: string;
    new_to_date: string;
    special_price: number;
    special_from_date: string;
    special_to_date: string;
}

interface SubCategory {
    id: string;
    name: string;
    children: SubCategory[];
    products: {
        items: any[];
    };
}

interface Category {
    id: string;
    name: string;
    children: SubCategory[];
}

interface PromoBlockResponse {
    data: {
        cmsBlocks: {
            items: any[]
        }
    }
}

interface StateContextProps {
    categoriesData: Category[]
    blockData1: PromoBlockResponse | null;
    blockData2: PromoBlockResponse | null;
    loading: boolean;
    firstLevelCategories?: string[];
    error: any;
}

const CategoryContext = createContext<StateContextProps | undefined>(undefined);

const getProductsFromSubcategories = (subcategories: SubCategory[]): Product[] => {
    let products: Product[] = [];

    subcategories.forEach(sub => {
        products = [...products, ...sub.products.items];

        if (sub.children) {
            products = [...products, ...getProductsFromSubcategories(sub.children)];
        }
    });

    return products;
};

export const CategoriesProductsStateProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [categoriesData, setCategoriesData] = useState<Category[]>([]);
    const [blockData1, setBlockData1] = useState<PromoBlockResponse | null>(null);
    const [blockData2, setBlockData2] = useState<PromoBlockResponse | null>(null);
    const [firstLevelCategories, setFirstLevelCategories] = useState<string[]>([]);
    const [localData, setLocalData] = useLocalStorageState('promo_data', { defaultValue: null });
    const categoryId = '402';
    const pageSize = '50';
    const currentPage= '1';
    const timeToRefresh = 30 * 60 * 1000; // 30 minutes

    const isValidData = (data) => {
        if (!data || !data.timestamp) return false;
        const now = new Date().getTime();
        const elapsed = now - data.timestamp;
        return elapsed < timeToRefresh;
    };

    const cmsBlockQuery = useQuery({
        queryKey: ['cmsBlocks'],
        queryFn: async () => {
            return request(getCmsBlock, {
                identifier1: "black_friday_promotii_1",
                identifier2: "black_friday_promotii_2"
            });
        },
        enabled: true,
        staleTime: 900000,
    });

    useEffect(() => {
        if (cmsBlockQuery.data) {
            const blockData1 = cmsBlockQuery?.data?.data?.block1?.items[0];
            const blockData2 = cmsBlockQuery?.data?.data?.block2?.items[0];
            setBlockData1(blockData1);
            setBlockData2(blockData2);
        }
    }, [cmsBlockQuery.isSuccess]);

    const categoriesDataQuery = useQuery({
        queryKey: ['categoriesData'],
        queryFn: async () => {
            return request(getCategoryAndProducts, {
                categoryId,
                pageSize,
                currentPage
            });
        },
        initialData: localData && isValidData(localData) ? localData.data : undefined,
        enabled: !localData || !isValidData(localData),
        onSuccess: (response) => {
            const categoryList = response.data.categoryList;
            const formattedData = {
                data: categoryList,
                timestamp: new Date().getTime()
            };
            setLocalData(formattedData);
            setCategoriesData(categoryList);
        }
    });

    useEffect(() => {
        if (localData && isValidData(localData)) {
            setCategoriesData(localData.data);
        }
    }, [localData]);

    useEffect(() => {
        const newFirstLevelCategories = categoriesData.map(category => category.name);
        setFirstLevelCategories(newFirstLevelCategories);
    }, [categoriesData]);

    return (
        <CategoryContext.Provider
            value={
                {
                    firstLevelCategories,
                    categoriesData,
                    blockData1, blockData2,
                    loading: cmsBlockQuery.isLoading || categoriesDataQuery.isLoading,
                    error: cmsBlockQuery.error || categoriesDataQuery.error
                }}>
            {children}
        </CategoryContext.Provider>
    );
};

export const useCategoriesAndProductsContextProvider = () => {
    const context = useContext(CategoryContext);
    if (!context) {
        throw new Error('useAppState must be used within a CategoryContext');
    }
    return context;
};
