import React, {useState} from 'react';
import {useWindowSize} from '../../../../general';
import {QueryClientProvider} from "@tanstack/react-query";
import {client} from "../../../Infrastructure/models/client";
import {CheckoutProvider} from "../../../Checkout";
import {AutoRelated} from "./Related/AutoRelated";
import {ProductViewProvider} from "../../state";
import {Description} from "./ProductDetails/Description";
import {Review} from "./ProductDetails/Review";
import {ReviewForm} from "./ProductDetails/ReviewForm";

interface ProductSku {
    productSku: string;
}

const queryClient = client();

export const ProductDetails: React.FC<ProductSku> = ({productSku}) => {
    const {isMobile} = useWindowSize();

    return (
        <QueryClientProvider client={queryClient}>
            <CheckoutProvider>
                <ProductViewProvider productSku={productSku} config={{}}>
                    <div>
                        <Description/>
                        <AutoRelated/>
                    </div>
                    <Review/>
                    <ReviewForm productSku={productSku}/>
                </ProductViewProvider>
            </CheckoutProvider>
        </QueryClientProvider>
    );
};

